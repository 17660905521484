<template>
  <v-app>
    <div class="mx-auto my-auto" v-if="loading">
      <RotateLoader color="#2976D2" />
    </div>
    <router-view v-if="!loading" />
  </v-app>
</template>

<script>
import RotateLoader from "vue-spinner/src/RotateLoader.vue";
import i18n from "./plugins/i18n";

export default {
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    language() {
      return i18n.locale;
    },
  },
  components: {
    RotateLoader,
  },

  mounted() {
    let urlParams = new URLSearchParams(window.location.search);

    const hasLanguageParams = urlParams.has("language");
    const languageParams = urlParams.get("language");

    if (hasLanguageParams) {
      if (languageParams === "ar") {
        this.$vuetify.rtl = true;
      } else {
        this.$vuetify.rtl = false;
      }
    } else {
      this.$vuetify.rtl = false;
    }
  },

  // watch if channge
  watch: {
    language() {
      // localStorage.setItem("language", i18n.locale);
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: kalameh, "Arial Narrow", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
