import {
  mobileModel,
  osVersion,
  fullBrowserVersion,
} from "mobile-device-detect";
import axios from "axios";
import { baseUrl } from "../constants/urls";

const requestHeaders = {
  "ocp-apim-subscription-key": "f5a019f020aa4569bae71d1b3eedc3d1",
};
// ** third party api for detecting user ip
const ipAddressAsync = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getClientData = async () => {
  const ipAddress = await ipAddressAsync();
  return {
    deviceModel: mobileModel,
    platform: window.navigator.platform,
    osVersion: osVersion,
    fullBrowserVersion: fullBrowserVersion,
    clientIp: ipAddress,
    placeId: localStorage.placeId,
    language: localStorage.language,
    sessionId: sessionStorage.sessionId,
  };
};

// use clientData here

// ** MAP_OPEN
export const postOpenMapEvent = async () => {
  const clientData = await getClientData();

  return await axios
    .post(
      `${baseUrl}/webn/event/${localStorage.placeId}`,
      {
        clientData: clientData,
        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),
        },
        eventKey: "MAP_OPEN",
      },
      {
        headers: requestHeaders,
      }
    )
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

// ** REMOVE_ROUTE
export const postRemoveRouteEvent = async (data) => {
  const clientData = await getClientData();
  const { endPoint, startPoint, route, eta } = data;

  const routeIdsArray = [];

  routeIdsArray.push(startPoint);

  route.forEach((item) => {
    routeIdsArray.push(item.id);
  });

  return await axios
    .post(
      `${baseUrl}/webn/event/${localStorage.placeId}`,
      {
        clientData: clientData,
        eventKey: "REMOVE_ROUTE",
        eventData: {
          // currentLocation: [41.2603330022984, 28.741013359892847],
          routeDuration: eta,
          endPoint: endPoint,
          routeLength: routeIdsArray.length,
          startPoint: startPoint,
          timestamp: Math.floor(new Date().getTime() / 1000),
          route: routeIdsArray,
        },
      },
      {
        headers: requestHeaders,
      }
    )
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

// ** GET_ROUTE
export const postRouteAnalyses = async (data) => {
  const clientData = await getClientData();
  const routeIdsArray = [];

  console.log("data.routeType", data.routeType);

  routeIdsArray.push(data.startPoint);

  data.route.forEach((item) => {
    routeIdsArray.push(item.id);
  });
  return await axios
    .post(
      `${baseUrl}/webn/event/${localStorage.placeId}`,
      {
        clientData: clientData,
        eventData: {
          startLocation: data.startLocation,
          startPoint: data.startPoint,
          endPoint: data.endPoint,
          timestamp: Math.floor(new Date().getTime() / 1000),
          route: routeIdsArray,
          routeLength: data.distance,
          routeDuration: data.eta,
          routeType: data.routeType ? data.routeType : "",
        },
        eventKey: "GET_ROUTE",
      },
      {
        headers: requestHeaders,
      }
    )
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

// ** ROUTE_DESTINATION
export const postReachDestinationAsync = async (data) => {
  const clientData = await getClientData();
  return await axios.post(
    `${baseUrl}/webn/event/${localStorage.placeId}?`,
    {
      clientData: clientData,
      eventData: {
        ...data,
        timestamp: Math.floor(new Date().getTime() / 1000),
      },
      eventKey: "ROUTE_DESTINATION",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "f5a019f020aa4569bae71d1b3eedc3d1",
      },
    }
  );
};

// **
export const postSearchDataAsync = async ({ keyword, type }) => {
  const clientData = await getClientData();
  return axios.post(
    `${baseUrl}/webn/event/${localStorage.placeId}?`,
    {
      clientData: clientData,
      eventData: {
        keyword: keyword,
        timestamp: Math.floor(new Date().getTime() / 1000),
      },

      eventKey: type,
    },
    {
      headers: requestHeaders,
    }
  );
};

export const postAnalysesDataAsync = async ({ eventData, eventKey }) => {
  const clientData = await getClientData();
  return axios.post(
    `${baseUrl}/webn/event/${localStorage.placeId}?`,
    {
      clientData: clientData,
      eventData: eventData,
      eventKey: eventKey,
    },
    {
      headers: requestHeaders,
    }
  );
};
