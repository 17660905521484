import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
// import * as Sentry from "@sentry/vue";
import device from "vue-device-detector";

import "./assets/styles/global-style.css";
import "./assets/fonts/fontiran.css";
// import "./assets/styles/material-design-icon.css";

// Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(device);
