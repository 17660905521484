import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Error from "../views/Error.vue";
const Home = () => import("../views/Home.vue");
import { v4 as uuidv4 } from "uuid";

// Generate a UUID
const uuid = uuidv4();

// Store the sessionId in the session
sessionStorage.setItem("sessionId", uuid);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      localStorage.placeId = to.query.placeId;

      const parking = to.query.parking ? JSON.parse(to.query.parking) : "";
      const navigation = to.query.navigation
        ? JSON.parse(to.query.navigation)
        : "";

      const campaign = to.query.campaign ? JSON.parse(to.query.campaign) : "";
      const virtualKeyboard = to.query.vk ? JSON.parse(to.query.vk) : "";
      const bearing = to.query.bearing ? JSON.parse(to.query.bearing) : "";
      const pitch = to.query.pitch ? JSON.parse(to.query.pitch) : "";
      const destinationDialog = to.query.destinationDialog
        ? JSON.parse(to.query.destinationDialog)
        : "";

      const focusStoreId = to.query.focusStoreId ? to.query.focusStoreId : "";
      const focusLat = to.query.focusLat ? to.query.focusLat : "";
      const focusLon = to.query.focusLon ? to.query.focusLon : "";

      const zoomLevel = to.query.zoomLevel
        ? parseFloat(to.query.zoomLevel)
        : "";

      const topPoiCount = to.query.topPoiCount
        ? JSON.parse(to.query.topPoiCount)
        : "";

      // ** multiple store id
      const multiStoreIds = to.query.multiStoreIds
        ? to.query.multiStoreIds
        : [];

      let splitStoreIds = [];
      if (multiStoreIds.length > 0) {
        splitStoreIds = multiStoreIds.split(",");
      } else {
        splitStoreIds = [];
      }

      store.dispatch("urlParamsConfigs", {
        focusStoreId: focusStoreId,
        zoomLevel: zoomLevel,
        campaign: campaign,
        navigation: navigation,
        parking: parking,
        topPoiCount: topPoiCount,
        multiStoreIds: splitStoreIds,
        virtualKeyboard: virtualKeyboard,
        bearing: bearing,
        pitch: pitch,
        destinationDialog: destinationDialog,
        focusCoords: {
          latitude: parseFloat(focusLat),
          longitude: parseFloat(focusLon),
        },
      });

      store.dispatch("getPlace").then(() => {
        store.dispatch("getPlaceConfig").then(() => {
          next();
        });
      });
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
